<template>
  <div class="components-form-advanced-search">
    <!-- search start -->
    <a-form class="ant-advanced-search-form" :form="searchForm">
      <a-row :gutter="24">
        <a-col
          :span="6"
          style="display:block;"
        >
          <a-form-item label='订单号'>
            <a-input placeholder="Id" v-model="searchForm.Id"/>
          </a-form-item>
        </a-col>
        <a-col
          :span="6"
          style="display:block;"
        >
          <a-form-item label='修理厂'>
              <a-input placeholder="修理厂" v-model="searchForm.CustomerName"/>
          </a-form-item>
        </a-col>
        <a-col
          :span="6"
          style="display:block;"
        >
          <a-form-item label='配件供应商'>
              <a-input placeholder="配件供应商" v-model="searchForm.SupplierName"/>
          </a-form-item>
        </a-col>
        <a-col
          :span="6"
          style="display:block;"
        >
          <a-form-item label='订单时间'>
            <a-range-picker v-model="searchForm.OrderTime" />
          </a-form-item>
        </a-col>
        <a-col :span="6" :style="{ display: expand ? 'block' : 'none' }">
          <a-form-item label='订单内容'>
              <a-input placeholder="订单内容" v-model="searchForm.Detail"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24" :style="{ textAlign: 'right' }">
          <a-button type="primary" @click="searchClick">
            查询
          </a-button>
          <a-button :style="{ marginLeft: '8px' }" @click="cleanSearchClick">
            清除
          </a-button>
          <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="expand = !expand">
            {{expand ? '收起' : '展开'}} <a-icon :type="expand ? 'up' : 'down'" />
          </a>
        </a-col>
      </a-row>
    </a-form>
    
    
    <!-- search end -->
    <!-- table start -->
    <div class="app-card">
      <!-- table 控制 start -->
      
      <a-row style="padding-bottom:16px" type="flex" justify="end">
        <a-col style="display: flex;">
          <a-button style="background-color: #13c2c2;color: #fff;border-color: #13c2c2;" v-if="$root.$data.privateState.action.includes('/AccessoriesOrders/CreateScoreOrder') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)" @click="createOrderClick">建立额度订单</a-button>
          <a-divider type="vertical" style="height:100%" />
          <a-button  icon="cloud-upload" type="danger" @click="reconciliationExcel" v-if="($root.$data.privateState.action.includes('/AccessoriesOrders/ReconciliationExcel') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role))">导出采购对账表</a-button>
          <a-divider type="vertical" style="height:100%" />
          <a-button  icon="cloud-upload" type="danger" @click="reconciliationExcelByCustomer" v-if="($root.$data.privateState.action.includes('/AccessoriesOrders/ReconciliationExcel') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role))">导出修理厂对账表</a-button>
          <a-divider type="vertical" style="height:100%" />
          <a-button-group>
            <a-tooltip placement="top">
              <template slot="title">
                <span>密度</span>
              </template>
              <a-dropdown :trigger="['click']">
                <a-button style="color: rgba(0,0,0,.65);" type="link" icon="column-height" />
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'default' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'default'">默认</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'middle' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'middle'">中等</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'small' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'small'">紧凑</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>全屏</span>
              </template>
              <a-button style="color: rgba(0,0,0,.65);" type="link" :icon="isFullscreen ? 'fullscreen-exit' : 'fullscreen'" @click="() => {screenfull.toggle();isFullscreen=!isFullscreen}"/>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>刷新</span>
              </template>
              <a-button style="color: rgba(0,0,0,.65);" type="link" icon="reload" @click="searchClick" />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>列设置</span>
              </template>
              <a-popover trigger="click" placement="bottom">
                <template slot="content">
                  <a-checkbox-group :value="showColumns" @change="changeColumns">
                    <a-row>
                      <a-col v-for="it of baseColumns" :key="it">
                        <a-checkbox :value="it">
                          {{it}}
                        </a-checkbox>
                      </a-col>
                    </a-row>
                  </a-checkbox-group>
                </template>
                <a-button style="color: rgba(0,0,0,.65);" type="link" icon="setting" />
              </a-popover>
            </a-tooltip>
          </a-button-group>
        </a-col>
      </a-row>
      <!-- table 控制 end -->
      <a-table
        :columns="columns"
        :rowKey="record => record.id"
        :dataSource="tableData"
        :pagination="pagination"
        :loading="loading"
        :scroll="{ x: 1300 }"
        @change="handleTableChange"
        bordered
        :size="tableTdStyle"
      >
        <template #createdAt="createdAt">
          {{moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}}
        </template>
        <template #action="data">
          <a-button v-if="($root.$data.privateState.role === 'SuperAdmin' || $root.$data.privateState.action.includes('/AccessoriesOrders/EditScoreOrder'))" type="link" @click="editClick(data)">编辑</a-button>
          <a-button v-if="($root.$data.privateState.role === 'SuperAdmin' || $root.$data.privateState.action.includes('/AccessoriesOrders/EditScoreOrder'))" type="link" @click="deleteClick(data)">删除</a-button>
        </template>
      </a-table>
    </div>
    <!-- table end -->
    <a-modal
      title="设置订单"
      v-model="setOrderShow"
      @ok="setOrderOk"
      :keyboard="false"
      :maskClosable="false"
      width="60%"
    >
      <a-form-model ref="createForm" :model="createForm" :rules="createFormRules" v-viewer="{movable: false}" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="订单日期" prop="orderTime">
          <a-date-picker v-model="createForm.orderTime" />
        </a-form-model-item>
        <a-form-model-item label="修理厂" prop="customerInfo">
          <a-select
            :disabled="setMode == 'edit'"
            show-search
            label-in-value
            v-model="createForm.customerInfo"
            placeholder="选择修理厂"
            style="width: 100%"
            :filter-option="false"
            :not-found-content="fetchingCustomerData ? undefined : null"
            @search="fetchUser"
          >
            <a-spin v-if="fetchingCustomerData" slot="notFoundContent" size="small" />
            <a-select-option v-for="d in customerData" :key="d.uuid">
              {{ d.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="供应商" prop="supplierInfo">
          <a-select
            show-search
            label-in-value
            v-model="createForm.supplierInfo"
            placeholder="选择供应商"
            style="width: 100%"
            :filter-option="false"
            :not-found-content="fetchingSupplierData ? undefined : null"
            @search="fetchSupplier"
          >
            <a-spin v-if="fetchingSupplierData" slot="notFoundContent" size="small" />
            <a-select-option v-for="d in supplierData" :key="d.uuid">
              {{ d.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- <a-form-model-item label="供应商" prop="supplierName">
          <a-input v-model="createForm.supplierName" />
        </a-form-model-item> -->
        <a-form-model-item label="明细" prop="detail">
          <a-textarea :auto-size="{ minRows: 2, maxRows: 6 }" v-model="createForm.detail" />
        </a-form-model-item>
        <a-form-model-item label="金额" prop="amount">
          <a-input-number style="width:100%" v-model="createForm.amount" />
        </a-form-model-item>
        <a-form-model-item label="挂帐金额" prop="amountCharge">
          <a-input-number style="width:100%" v-model="createForm.amountCharge" />
        </a-form-model-item>
        <a-form-model-item label="转换积分" prop="toScore">
          <a-input-number style="width:100%" v-model="createForm.toScore" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 导出报表 -->
    <a-modal
      v-model="showExportExcelDateSelect"
      title="导出报表"
      @ok="exportExcel"
      @cancel="showExportExcelDateSelect = false"

    >
      <a-row>
        <a-range-picker style="width:100%" :disabled-date="c => c > moment().endOf('day')" v-model="exportExcelDate" />
      </a-row>
    </a-modal>
    <a-modal
      :width="200"
      :visible="modalLoading"
      :closable="false"
      :maskClosable="false"
      :footer="null"
    >
      <a-row>
        <a-col style="text-align: center;">
          <a-icon type="loading" :style="{ fontSize: '36px', color: '#1890ff' }" />
        </a-col>
        <a-col style="text-align: center;color:#1890ff;padding-top:10px;">
          {{modalLoadingMessage}}
        </a-col>
      </a-row>
      
    </a-modal>
  </div>
</template>

<script>
import licia from 'licia'
import moment from 'moment'
import DownloadJS from "downloadjs";
import screenfull from 'screenfull'

import { AccessoriesOrders, Customer, ASUsers } from '@/api'
import { aliveVM, mixinTableList } from '@/utils/mixin'

const INIT_SEARCH_FORM = '{"Id":"","Detail":"","CustomerName":"","SupplierName":"","OrderTime":[]}'
const columns = [
  {
    title: 'ID',
    width: 150,
    dataIndex: 'id',
  },
  {
    title: '金额',
    width: 150,
    dataIndex: 'amount',
  },
  {
    title: '挂帐金额',
    width: 150,
    dataIndex: 'amountCharge',
  },
  {
    title: '转换积分',
    width: 150,
    dataIndex: 'toScore',
  },
  {
    title: '修理厂',
    width: 150,
    dataIndex: 'customer.name',
  },
  {
    title: '供应商',
    width: 120,
    dataIndex: 'supplierName',
  },
  {
    title: '订单时间',
    dataIndex: 'orderTime',
    scopedSlots: { customRender: 'orderTime' },
  },
  {
    title: '操作',
    key: 'operation',
    width: 130,
    fixed: 'right',
    scopedSlots: { customRender: 'action' },
  }
]
export default {
  name: 'AccessoriesOrdersScore',
  mixins: [aliveVM, mixinTableList],
  created () {
    const baseColumns = []
    for (const it of columns) {
      baseColumns.push(it.title)
    }
    this.$set(this, 'baseColumns', baseColumns)
    this.fetch()
  },
  async mounted () {
  },
  computed: {
    showColumns() {
      let c = []
      for (const it of this.columns) {
        c.push(it.title)
      }
      return c
    }
  },
  data () {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 12 },
      screenfull,
      isFullscreen: false,
      expand: false,
      tableListApi: `${AccessoriesOrders}/TableListByScore`,
      searchParams: null,
      searchForm: JSON.parse(INIT_SEARCH_FORM),
      baseColumns: [],
      columns,
      modalLoading: false,
      modalLoadingMessage: '正在处理数据..',
      // 其他
      moment,
      setMode: 'create',
      setId: '',
      createForm: {
        orderTime: null,
        customerInfo: '',
        supplierInfo: '',
        supplierName: '',
        detail: '',
        amount: 0,
        amountCharge: 0,
        toScore: 0
      },
      createFormRules: {
        orderTime: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        customerInfo: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        supplierInfo: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        supplierName: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        detail: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
      },
      setOrderShow: false,
      fetchingCustomerData: false,
      customerData: [],
      fetchingSupplierData: false,
      supplierData: [],
      // 导出
      exportExcelDate: [],
      exportExcelType: '',
      showExportExcelDateSelect: false
    }
  },
  methods: {
    changeColumns (v) {
      const c = [];
      for (const it of columns) {
        if (v.includes(it.title)) {
          c.push(it)
        }
      }
      this.$set(this, 'columns', c)
    },
    async reload() {
      this.$set(this, 'searchParams', null)
      this.searchForm.resetFields()
      await this.fetch()
    },
    async cleanSearchClick () {
      this.$set(this, 'searchParams', null)
      this.$set(this, 'searchForm', JSON.parse(INIT_SEARCH_FORM))
      await this.fetch()
    },
    async searchClick () {
      this.pagination.current = 1
      const searchForm = JSON.parse(JSON.stringify(this.searchForm))
      const searchParams = {}
      for (const k of Object.keys(searchForm)) {
        const it = searchForm[k]
        if (k === "OrderTime") {
          if (!it || it.length < 1) continue;
          it[0] = moment(it[0]).format("YYYY-MM-DD") + " 00:00:00";
          it[1] = moment(it[1]).format("YYYY-MM-DD") + " 23:59:59";
          searchParams[k] = it;
        }
        if (k === 'Role' && it) {
          searchParams[k] = parseInt(it)
          continue
        }
        if (k === 'Belong' && it) {
          searchParams[k] = it.key
          continue
        }
        if (licia.isNum(it)) {
          searchParams[k] = it
          continue
        }
        if (!licia.isEmpty(it)) {
          searchParams[k] = it
        }
      }
      this.$set(this, 'searchParams', searchParams)
      this.fetch()
    },
    // other
    async fetchUser(value) {
      this.fetchingCustomerData = true;
      const res = await this.$axios
          .post(`${Customer}/SearchSelect`, {
            name: value
          })
          .catch((e) => {
            return {
              status: false,
              message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
            };
          });
      if (!res.status) return
      this.$set(this, 'customerData', res.data)
      this.fetchingCustomerData = false;
    },
    async fetchSupplier(value) {
      this.fetchingSupplierData = true;
      const res = await this.$axios
          .post(`${ASUsers}/SearchSelect`, {
            name: value
          })
          .catch((e) => {
            return {
              status: false,
              message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
            };
          });
      if (!res.status) return
      this.$set(this, 'supplierData', res.data)
      this.fetchingSupplierData = false;
    },
    createOrderClick () {
      this.setMode = 'create'
      this.fetchingCustomerData = false;
      this.$set(this, 'customerData', [])
      this.$set(this, 'createForm', {
        orderTime: null,
        customerInfo: '',
        supplierName: '',
        detail: '',
        amount: 0,
        amountCharge: 0,
        toScore: 0
      })
      this.setOrderShow = true
    },
    async editClick (data) {
      this.setId = data.id
      this.setMode = 'edit'
      this.$set(this, 'customerData', [{uuid: data.customerInfo.key, name: data.customerInfo.label}])
      if (data.supplierInfo && data.supplierInfo.key) {
        this.$set(this, 'supplierData', [{uuid: data.supplierInfo.key, name: data.supplierInfo.label}])
      }
      this.$set(this, 'createForm', {
        orderTime: data.orderTime,
        customerInfo: data.customerInfo,
        supplierInfo: data.supplierInfo ? data.supplierInfo : '',
        supplierName: data.supplierName,
        detail: data.detail,
        amount: data.amount,
        amountCharge: data.amountCharge,
        toScore: data.toScore
      })
      this.setOrderShow = true

    },
    deleteClick(data) {
      const _this = this;
      this.$confirm({
        title: '删除订单?',
        content: '是否确认删除订单',
        async onOk() {
          const res = await _this.$axios
            .delete(`${AccessoriesOrders}/DeleteScoreOrder?id=${data.id}`)
            .catch((e) => {
              return {
                status: false,
                message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
              };
            });
          if (!res.status) {
            _this.$message.error(res.message);
            return;
          }
          _this.setOrderShow = false;
          _this.$message.success("操作成功");
          _this.fetch();
          return;
        },
        onCancel() {},
      });
    },
    async setOrderOk () {
      this.$refs.createForm.validate(async (valid) => {
        if (!valid) {
          this.$message.error("请检查填写内容");
          return;
        }
        const postData = JSON.parse(JSON.stringify(this.createForm));
        if (!postData.supplierInfo || !postData.supplierInfo.label) {
          this.$message.error("请选择供应商");
          return;
        }
        postData.supplierName = postData.supplierInfo.label;
        let url = ''
        if (this.setMode == 'create') {
          url = `${AccessoriesOrders}/CreateScoreOrder`
        } else if (this.setMode == 'edit') {
          url = `${AccessoriesOrders}/EditScoreOrder?id=${this.setId}`
        } else {
          this.$message.error("请求类型错误")
          return
        }
        const res = await this.$axios
          .post(url, postData)
          .catch((e) => {
            return {
              status: false,
              message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
            };
          });
        if (!res.status) {
          this.$message.error(res.message);
          return;
        }
        this.setOrderShow = false;
        this.$message.success("操作成功");
        this.fetch();
      })
    },
    reconciliationExcel() {
      this.exportExcelDate = []
      this.exportExcelType = 'reconciliationExcel'
      this.showExportExcelDateSelect = true
    },
    reconciliationExcelByCustomer() {
      this.exportExcelDate = []
      this.exportExcelType = 'reconciliationExcelByCustomer'
      this.showExportExcelDateSelect = true
    },
    async exportExcel() {
      if (!this.exportExcelDate || this.exportExcelDate.length < 2) {
        this.$message.error('请选择导出月份');
        return;
      }
      const searchParams = {
        orderTime: [this.exportExcelDate[0].format('YYYY-MM-DD') + ' 00:00:00', this.exportExcelDate[1].format('YYYY-MM-DD')  + ' 23:59:59']
      }
      
      this.modalLoading = true
      let url = ""
      if (this.exportExcelType == 'reconciliationExcel') {
        url = `${AccessoriesOrders}/ReconciliationExcel`;
      } else if (this.exportExcelType == 'reconciliationExcelByCustomer') {
        url = `${AccessoriesOrders}/ReconciliationExcelByCustomer`
      }
      const apiRes = await this.$axios.post(url, {
        results: 20000,
        page: 1,
        searchParams,
      }).catch(e => {
        return {
          status: false,
          message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
        }
      })
      this.modalLoading = false
      if (!apiRes.status) {
        this.sending = false
        this.$message.error(apiRes.message)
        return
      }
      this.showExportExcelDateSelect = false
      if (this.exportExcelType == 'reconciliationExcel') {
        DownloadJS(window.atob(apiRes.data), '统计表数据.xlsx')
      } else if (this.exportExcelType == 'reconciliationExcelByCustomer') {
        DownloadJS(window.atob(apiRes.data), '修理厂对账统计表数据.xlsx')
      }
      
    }
  }
}
</script>

<style>

</style>